//Products.js file

import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../../components/functions'

export const Products = ({ slice }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.items

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    // const clickDatalayer = (data) => {
    //     //Data layer - Product details clicked
    //     window.dataLayer = window.dataLayer || []
    //     window.dataLayer.push(data)
    // }

    return(
        <section
            className={(isInView)? 'products pb-5 pt-3 pt-lg-0 d-flex justify-content-center align-items-center in-view' : 'products pb-5  pt-3 pt-lg-0 d-flex justify-content-center align-items-center'}
            ref={ref}>
            <div className='container py-0 pb-lg-5 position-relative'>
                <div className='row p-0 justify-content-center product-wrapper'>
                {
                        data.map((product, index) => {
                            return (
                                <div key={"col-"+ index } className={'col-lg-3 brand-product-item px-3 pb-5 slide-up animation-delay-' + index}>
                                    <div className='bottle-shot mb-3 d-flex justify-content-center align-items-center'>
                                        {(product.product_list_image)?
                                        <img src={product.product_list_image.url} height="90%" alt={(product.product_list_image.alt)? product.product_list_image.alt : "Wine Image"} />
                                        : null}
                                    </div>
                                    {(product.brand_name.text)?
                                    <p className='brand-name'>{product.brand_name.text}</p>:
                                    null}
                                    {(product.product_name.text)?
                                    <h5 className='mt-2 mt-lg-2'>
                                        {product.product_name.text}
                                    </h5>
                                    : null}
                                    {(product.product_description.text)?
                                    <p className='desc mt-2 mt-lg-3'>{product.product_description.text}</p>
                                    : null}
                                    {(product.button_link.url)?
                                        (product.button_link.link_type === "Document" && product.button_link.uid)?
                                        <Link 
                                            to={"/" + product.button_link.uid}
                                            onClick={() => {
                                                // let pageHost = new URL(window.location.href)
                                                // let pageUrl = pageHost.host;
                                                // pageUrl +=  (product.button_link.uid === "5v")? "/5V" : "/" + product.button_link.uid;

                                                // let dl = {
                                                //     'event': 'where_to_buy_clicked',
                                                //     'brand_name': (product.brand_name.text)? `${product.brand_name.text.toLowerCase()}` : '',
                                                //     'product_name': (product.product_name.text)? `${product.product_name.text.toLowerCase()}` : '',
                                                //     'page_name': pageUrl,
                                                //     'referral': 'perfect wines for page',
                                                //     'tile_position': (index + 1),
                                                //     'page_location': window.location.href
                                                // }
                                                // clickDatalayer(dl)
                                            }}
                                        >
                                            <button className='btn btn-primary-2'>{(product.button_link_text.text)? product.button_link_text.text : "Find where to buy"}</button>
                                        </Link>
                                        :
                                        <a 
                                            href={product.button_link.url} 
                                            target={(product.button_link.target)? product.button_link.target : "_self"}
                                            onClick={() => {
                                                // let dl = {
                                                //     'event': 'where_to_buy_clicked',
                                                //     'brand_name': (product.brand_name.text)? `${product.brand_name.text.toLowerCase()}` : '',
                                                //     'product_name': (product.product_name.text)? `${product.product_name.text.toLowerCase()}` : '',
                                                //     'page_name': product.button_link.url,
                                                //     'referral': 'perfect wines for page',
                                                //     'tile_position': (index + 1),
                                                //     'page_location': window.location.href
                                                // }
                                                // clickDatalayer(dl)
                                            }}
                                        >
                                            <button className='btn btn-primary-2'>{(product.button_link_text.text)? product.button_link_text.text : "Find where to buy"}</button>
                                        </a>
                                    : null}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}