import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/find-perfect-wine'

//components
import FindPerfectWineBanner from '../components/FindPerfectWineBanner'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const FindPerfectWine = ( props ) => {
    const {data} = props

    const pageData = data.prismicFindPerfectWinePage.data
    const [sliceData, setSliceData] = useState([])

    console.log(pageData);

    useEffect(() => {
      /* ADD MODULES AND SECTIONS TO SLICEDATA */
      const newSliceData = pageData.body

      // Our Brands Module
      var moduleOurBrand = data.prismicOurBrandsSection.data
      var ourBrandSliceData = {
          slice_type: "our_brands_section",
          primary: {
            section_title: moduleOurBrand.section_title,
            section_desc: moduleOurBrand.section_text_field,
            brand_link_image_text_group: moduleOurBrand.brand_link_image_text_group
          }
      }
      var checkOurBrandDuplicateData = newSliceData.map((item) => {
        if(item.slice_type === "our_brands_section") return true;
        return false;
      })
      if(!checkOurBrandDuplicateData.includes(true))  newSliceData.push(ourBrandSliceData)

      setSliceData(newSliceData)

      //Scroll to the top on new page
      document.documentElement.style.scrollBehavior = 'auto'
      window.scrollTo(0,0)
    }, [
      data.prismicOurBrandsSection.data,
      pageData.body
    ])


    return(
        <Layout currentPage="find-perfect-wine" customPage={true}>
            <Seo
              title={(pageData.meta_title)? pageData.meta_title.text : null}
              description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <FindPerfectWineBanner
              title={pageData.banner_title.text}
              subtitle={pageData.banner_subtitle.text}
              description={pageData.banner_description.html}
              mobileBackgroundUrl={(pageData.banner_image_mobile)? pageData.banner_image_mobile.url : null}
              backgroundUrl={(pageData.banner_image)? pageData.banner_image.url : null}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}

export default withPrismicPreview(FindPerfectWine)

export const query = graphql`
    query FindPerfectWineQuery($uid: String){
        prismicFindPerfectWinePage(uid: {eq: $uid}) {
            _previewable
            data {
              banner_description {
                text
                html
              }
              banner_subtitle {
                text
              }
              banner_title {
                text
              }
              banner_image {
                alt
                url
              }
              banner_image_mobile {
                alt
                url
              }
              body {
                ... on PrismicFindPerfectWinePageDataBodyProductList {
                  id
                  slice_type
                  items {
                    brand_name {
                      text
                    }
                    button_link {
                      url
                      type
                      target
                      uid
                      link_type
                    }
                    button_link_text {
                      text
                    }
                    product_description {
                      html
                      text
                    }
                    product_list_image {
                      alt
                      url
                    }
                    product_name {
                      text
                    }
                  }
                }
              }
              meta_description {
                text
              }
              meta_title {
                text
              }
            }
        }
        prismicOurBrandsSection{
          _previewable
          data {
            section_title {
              text
            }
            section_text_field {
              text
            }
            brand_link_image_text_group {
              brand_image {
                alt
                copyright
                url
              }
              brand_link {
                size
                slug
                tags
                target
                type
                uid
                url
                link_type
                lang
                isBroken
                id
              }
              brand_name {
                richText
                text
                html
              }
            }
          }
      }
    }
`