import React, { useState, useEffect } from 'react'

const FindPerfectWineBanner = ({ title, backgroundUrl, subtitle, description, mobileBackgroundUrl }) => {
    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size
    const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    //create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])
    return(
        <section className='in-view find-perfect-wine__banner'>
            {(backgroundUrl)?
                <div className='in-view find-perfect-wine__banner-bg page-banner zoom-out-bg-wo-opacity' style={{backgroundImage: `url(${(isMobile && mobileBackgroundUrl)? mobileBackgroundUrl : backgroundUrl})`}}></div>
                : null
            }
            <div className="find-perfect-wine__banner-content text-center slide-up d-flex justify-content-center align-items-center py-5">
                <div className="py-0 pt-lg-5 mt-5 w-100">
                    {(subtitle)? 
                        <h6>{subtitle}</h6>
                    : null}
                    {(title)?
                        <h2><span className='px-5'>{title}</span></h2>
                    : null}
                    {(description)?
                        <div className='mx-auto desc-wrapper mt-lg-5 pt-3' dangerouslySetInnerHTML={{ __html: description }}/>
                    : null}
                </div>
            </div>
        </section>
    )
}

export default FindPerfectWineBanner